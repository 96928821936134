@import '../../../styles/variables.scss';

.section-title {
	margin: 32px 0 24px;
	position: relative;

	.title {
		color: $primary_may_300;
		font-size: 11px;
		line-height: 24px;
		font-weight: $medium;
		text-transform: uppercase;
		background-color: $white;
		position: relative;
		padding-right: 8px;
		z-index: 1;
	}

	&:after {
		content: '';
		width: 100%;
		position: absolute;
		top: 50%;
		left: 0;
		border-top: 1px solid $primary_may_50;
	}

	.tag {
		margin: 0;
		position: absolute;
		top: calc(50% - 12px);
		right: 0;
		padding-left: 8px;
		z-index: 1;
		background-color: $white;
	}
}
