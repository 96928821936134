@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.drawer {
	padding-right: 8px !important;
}

.edit-policy {
	flex-grow: 1;

	form {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.details {
			margin-top: 0;
			margin-bottom: 0;
		}

		.inputs {
			max-height: calc(100vh - 200px);

			.checkbox-group {
				margin-bottom: 24px;
				
				.input {
					width: auto;
					display: inline-block;
					margin-right: 6px;
				}

				.info {
					width: 14px;
					height: 14px;
					cursor: pointer;
					vertical-align: middle;
					display: inline-block;

					&:before {
						content: '';
						display: inline-block;
						width: 14px;
						height: 14px;
						background: url('../../../../assets/images/icon-question-circle.svg')
							no-repeat center center;
						background-size: contain;
					}
				}
			}

			.input {
				width: 100%;

				& + .input {
					margin-top: 24px;
				}
			}

			.input-group {
				margin: 24px 0;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-between;
				align-items: flex-start;

				.input {
					margin-top: 0;
					flex: 0 1 calc(50% - 8px);
				}

				.input-group {
					margin: 0;
					flex: 0 1 calc(50% - 8px);
					align-items: flex-end;
				}
			}
		}

		.actions {
			padding-right: 16px;
		}

		.scrollbar {
			padding-right: 16px;
		}
	}

	.collapsible-trigger {
		margin-bottom: 0;
	}
}
