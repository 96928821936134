@import '../../styles/variables';
@import '../../styles/mixins';

.verify {
	width: 500px;
	min-height: 400px;

	.form {
		margin-top: 60px;

		p {
			font-family: Lexend;
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			text-align: left;
		}

		.actions {
			margin-top: 60px;
		}
	}


	.error {
		margin-top: 16px;
		text-align: center;
	}
}

.inputs-wrap {
	display: flex;
	gap: 16px;
	align-items: center;
	justify-content: center;
	margin-top: 26px;

	.input {
		width: 40px;
		height: 40px;
		padding: 8px;
		border-radius: 12px;
		border: 1px solid $primary_may_300;
		box-sizing: border-box;
		text-align: center;
		&.inputError {
			border-color: $system_red_300;
		}
	}
}
