@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.label {
	display: block;
	font-size: 10px;
	line-height: 12px;
	font-weight: $medium;
	color: $primary_may_500;
	margin-bottom: 4px;
}

.input {
	border: 1px solid $primary_may_75;
	background-color: $primary_may_10;
	border-radius: 12px;
	color: $primary_may_500;
	font-size: 14px;
	line-height: 20px;
	padding: 8px 12px;
	outline: none;
	width: 100%;
	transition: all 0.2s linear;

	&::placeholder {
		color: $primary_may_300;
	}

	&:hover {
		background-color: $primary_may_25;
	}

	&:focus,
	&:active {
		background-color: $primary_may_25;
		border-color: $primary_may_200;
	}

	&:disabled,
	&:read-only {
		background-color: $primary_may_10;
		border-color: $primary_may_50;
		color: $primary_may_300;
		cursor: not-allowed;
	}
}

.search-wrapper {
	position: relative;

	.input {
		padding-left: 40px;

		&::-webkit-search-cancel-button {
			-webkit-appearance: none;
			cursor: pointer;
			display: inline-block;
			width: 20px;
			height: 20px;
			margin-left: 10px;
			border-radius: 4px;
			background-image: url('../../../../assets/images/icon-cross-thin-accent-light.svg');
			background-repeat: no-repeat;
			background-size: 12px 12px;
			background-position: center;
			transition: all 0.2s linear;

			&:hover {
				background-color: $primary_may_50;
			}
		}
	}

	&:before {
		content: '';
		position: absolute;
		left: 16px;
		top: calc(50% - 11px);
		width: 22px;
		height: 22px;
		background-image: url('../../../../assets/images/icon-search.svg');
		background-repeat: no-repeat;
		background-size: contain;
	}

	&.small {
		.input {
			border-radius: 10px;
			padding: 4px 8px;
			padding-left: 28px;
		}

		&:before {
			left: 8px;
			top: calc(50% - 8px);
			width: 16px;
			height: 16px;
		}
	}
}

.password-wrapper {
	position: relative;

	.input {
		padding-right: 48px;
	}

	.show-toggle {
		cursor: pointer;
		position: absolute;
		right: 16px;
		width: 20px;
		height: 18px;
		background-size: contain;
		background-repeat: no-repeat;

		&.show {
			background-image: url('../../../../assets/images/icon-eye-hide.svg');
			top: calc(50% - 9px);
		}

		&.hide {
			background-image: url('../../../../assets/images/icon-eye.svg');
			top: calc(50% - 7px);
		}
	}
}

.error {
	.input {
		border-color: $system_red_300;
	}
}

.disabled {
	.error {
		.input {
			border-color: $system_red_75;
		}
	}
}
