@import '../../../styles/variables';

.log-attempts-block {
	font-size: 14px;
	line-height: 20px;

	.attempt-block {
		&:hover {
			background-color: $primary_may_10;
		}

		.attempt-trigger {
			margin-bottom: 4px;
		}

		.attempt-name {
			font-weight: $semiBold;
			border-bottom: 0.5px solid $primary_may_50;
			padding: 8px;
		}
	}
}
