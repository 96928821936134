@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.basic-panel {
	width: 529px;
	padding: 32px 24px;
	position: relative;

	.title {
		.back {
			position: relative;
			display: inline-block;
			top: 0;
			left: 0;
			margin-right: 20px;
		}

		h2 {
			display: inline-block;
			margin: 0;
		}
	}

	.progress {
		position: absolute;
		right: 24px;
		top: 36px;
	}
}
