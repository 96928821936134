@import '../../../styles/mixins';
@import '../../../styles/variables';

.basic-layout {
	width: 100%;
	background-color: $primary_may_50;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}
