@import '../../../styles/variables.scss';

.change-status-block {
	display: flex;
	flex-direction: row;
	align-items: center;
	color: $primary_may_400;
	opacity: 0;
	transition:
		opacity 0.1s ease-in,
		margin-left 0.1s ease-in;
	visibility: hidden;
	height: 0;
	padding: 0 16px;
	margin-left: -24px;
	transform: scale(0);

	&.show {
		opacity: 1;
		visibility: visible;
		padding: 16px 16px 8px;
		height: auto;
		margin-left: 0;
		transform: scale(1);
		z-index: 1;
		position: relative;
	}

	.select {
		width: 155px;
		margin-left: 8px;
	}

	.loader {
		margin-left: 8px;
	}
}
