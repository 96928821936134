@import '../../../styles/variables';
@import '../../../styles/mixins';

.navigation {
	.items {
		.item {
			display: block;
			text-decoration: none;
			background: transparent;
			color: $primary_may_25;
			padding: 14px 24px;
			transition: all 200ms linear;
			font-size: 14px;
			line-height: 20px;
			font-weight: $semiBold;
			border-top: 1px solid transparent;
			border-bottom: 1px solid transparent;

			&:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				margin-right: 12px;
				width: 20px;
				height: 20px;
				background-size: contain;
				background-repeat: no-repeat;
			}

			&:hover,
			&:active,
			&:focus,
			&.active {
				background: linear-gradient(
					90deg,
					#5baf23 0%,
					rgba(91, 175, 35, 0.536233) 28.5%,
					rgba(91, 175, 35, 0) 100%
				);
				border-color: rgba(#5BAF2399, 0.4);
			}

			& + .item {
				margin-top: 8px;
			}

			&.companies:before {
				background-image: url('../../../assets/images/icon-companies.svg');
			}

			&.admins:before {
				background-image: url('../../../assets/images/icon-users.svg');
			}

			&.policies:before {
				background-image: url('../../../assets/images/icon-policies.svg');
			}

			&.ai-logs:before {
				background-image: url('../../../assets/images/icon-logs.svg');
			}

			&.framework-crosswalk:before {
				background-image: url('../../../assets/images/icon-frameworks.svg');
			}
		}
	}
}
