@import '../../../styles/variables';

.tag {
	display: inline-block;
	font-size: 11px;
	line-height: 16px;
	text-transform: uppercase;
	font-weight: $medium;
	padding: 2px 8px;
	border-radius: 4px;
	margin: 2px;
	border: 1px solid transparent;
	white-space: nowrap;
	transition: all 0.1s linear;

	&.with-close {
		padding-left: 24px;
		position: relative;
	}

	&.with-tooltip {
		cursor: pointer;
	}

	&.with-remove-on-hover {
		&:hover {
			position: relative;
			padding-left: 24px;
			background-color: $system_red_50;
			border-color: $system_red_75 !important;

			.remove {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&.small {
		border-radius: 16px;
		padding: 2px 4px;
		font-size: 10px;
		line-height: 12px;
		font-weight: $medium;
		text-transform: none;
	}

	&.medium {
		border-radius: 16px;
		padding: 4px 8px;
		font-size: 12px;
		line-height: 16px;
		font-weight: $medium;
	}

	.icon {
		display: inline-block;
		vertical-align: bottom;
		width: 12px;
		height: 12px;
		margin-right: 4px;
	}

	.close,
	.remove {
		position: absolute;
		left: 8px;
		top: calc(50% - 6px);
		cursor: pointer;
		width: 12px;
		height: 12px;
	}

	.close {
		background: url('../../../assets/images/icon-close-circle.svg') no-repeat;
		background-size: contain;
	}

	.remove {
		opacity: 0;
		visibility: hidden;
		background: url('../../../assets/images/icon-cross-circle-error.svg') no-repeat;
		background-size: contain;
	}
}
