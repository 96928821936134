@import '../../../styles/variables';
@import '../../../styles/mixins';

.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 36px;
	font: {
		size: 14px;
		weight: $semiBold;
	}
	line-height: 20px;
	cursor: pointer;
	text-decoration: none;
	outline: none;
	margin: 0;
	border-radius: 12px;
	background: $primary_may_200;
	width: 100%;
	border: none;

	&.small {
		height: 32px;
		font-size: 12px;
		line-height: 16px;
	}

	.text {
		color: $primary_may_10;
	}

	&:hover {
		background: $primary_may_200;
		background: linear-gradient(90deg, $primary_may_200 31.49%, $primary_may_100 99.97%);
	}

	&:disabled {
		cursor: auto;
		background: $primary_may_75;
		pointer-events: none;
	}

	&.error {
		background: $system_red_300;

		&:hover {
			background: $system_red_300;
			background: linear-gradient(90deg, $system_red_300 31.49%, $system_red_200 99.97%);
		}

		&:disabled {
			cursor: auto;
			background: $system_red_75;
			pointer-events: none;
		}
	}

	&.negative {
		background: transparent;
		border: 2px solid $primary_may_200;

		.text {
			color: $primary_may_200;
		}

		&:disabled {
			border-color: $primary_may_75;

			.text {
				color: $primary_may_75;
			}
		}

		&:hover {
			border-color: $primary_may_100;
			background: $primary_may_10;
			border: 2px solid transparent;
			background: linear-gradient(to right, white, white),
				linear-gradient(90deg, $primary_may_200 31.49%, $primary_may_100 99.97%);
			background-clip: padding-box, border-box;
			background-origin: padding-box, border-box;
		}
	}
}
