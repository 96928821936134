@import '../../styles/variables';
@import '../../styles/mixins';

.scrollbar {
	padding-right: 16px;
	margin-bottom: 32px;

	:global {
		.ps__rail-y {
			background-color: $primary_may_25 !important;
		}
	}
}

.panels {
	max-height: calc(100vh - 100px);
}

.basic-panel {
	width: 524px;
	padding: 24px;

	& + .basic-panel {
		margin-top: 16px;
	}

	&.configs {
		padding-right: 0;
		padding-left: 0;
	}
} 

.user-info {
	margin-top: 32px;
}

.notifications {
	h3 {
		margin-bottom: 24px;
	}

	.switcher-wrap {
		display: inline-flex;
		align-items: center;
		justify-content: flex-start;

		:global {
			.react-switch-bg {
				border: 1px solid $primary_may_75;
			}
		}

		.off {
			:global {
				.react-switch-bg {
					border: 1px solid $primary_may_50;
				}
			}
		}

		.label {
			margin-left: 16px;
		}
	}
}

.configs {
	display: flex;
	flex-direction: column;

	.item {
		position: relative;
		display: inline-block;
		padding: 16px 48px 16px 56px;
		@extend %link;
		color: $primary_blueberry_500;
		cursor: pointer;
		font-size: 14px;
		line-height: 20px;
		font-weight: $semiBold;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			right: 24px;
			width: 6px;
			height: 12px;
			top: calc(50% - 6px);
			background-image: url('../../assets/images/icon-chevron-right-light.svg');
			background-repeat: no-repeat;
			background-size: contain;
		}

		&:hover {
			color: $primary_blueberry_500;
			background-color: $primary_may_25;

			&:after {
				background-image: url('../../assets/images/icon-chevron-right.svg');
			}
		}

		& + .item {
			margin-top: 8px;
		}

		&:before {
			position: absolute;
			top: calc(50% - 9px);
			left: 24px;
			content: '';
			width: 18px;
			height: 18px;
		}

		&.password:before {
			// background: url('../../assets/images/icon-key.svg') no-repeat;
			background-size: contain;
		}

		&.notification:before {
			// background: url('../../assets/images/icon-notes.svg') no-repeat;
			background-size: contain;
		}

		&.logout:before {
			background: url('../../assets/images/icon-logout-black.svg') no-repeat;
			background-size: contain;
		}
	}
}

.edit {
	position: absolute;
	right: 24px;
	top: 22px;
}

.recommendedLabel {
	position: absolute;
	right: 24px;
	top: 29px;
}

.profile-skeleton {
	.skeleton {
		display: inline-block;
		width: 50%;
	}

	br {
		display: none;
	}
}
