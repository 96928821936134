//Resolutions
$smallDesktop: 1280px;
$mediumDesktop: 1440px;
$largeDesktop: 1920px;

//Font weights
$light: 300;
$normal: 400;
$medium: 500;
$semiBold: 600;
$demiBold: 700;
$bold: 900;

//--

$black: #000;
$white: #fff;

//--

$primary_may_10: #FCFFF8;
$primary_may_25: #F6FCEF;
$primary_may_50: #F2FAE8;
$primary_may_75: #D3ECB3;
$primary_may_100: #A0D47D;
$primary_may_200: #62AB30;
$primary_may_300: #7A926A;
$primary_may_400: #415E2E;
$primary_may_500: #2C411D;
$primary_may_600: #0F2409;

$primary_blueberry_50: #e7e7eb;
$primary_blueberry_75: #9d9dac;
$primary_blueberry_100: #757589;
$primary_blueberry_200: #393957;
$primary_blueberry_500: #0a0a20;

$system_red_50: #fcedf0;
$system_red_75: #f1b4c3;
$system_red_200: #e36785;
$system_red_300: #dd486c;
$system_red_500: #c33c5d;

$system_green_50: #effced;
$system_green_75: #bdf1b4;
$system_green_200: #79e367;
$system_green_500: #47a738;

$system_orange_50: #fff6f0;
$system_orange_200: #ffb681;
$system_orange_500: #e68d4c;

$secondary_leaf_75: #d1f0ab;
$secondary_sunflower_75: #fff1aa;
