@import '../../../../../../styles/mixins';
@import '../../../../../../styles/variables';

.add-policy {
	margin-top: 32px;

	.input {
		& + .input {
			margin-top: 16px;
		}
	}

	.checkbox-group {
		margin-top: 24px;

		.input {
			width: auto;
			display: inline-block;
			margin-right: 6px;
		}

		.info {
			width: 14px;
			height: 14px;
			cursor: pointer;
			vertical-align: middle;
			display: inline-block;

			&:before {
				content: '';
				display: inline-block;
				width: 14px;
				height: 14px;
				background: url('../../../../../../assets/images/icon-question-circle.svg') no-repeat
					center center;
				background-size: contain;
			}
		}
	}

	.actions {
		margin-top: 32px;
	}
}
