@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';

.edit-two-factor {
	flex-grow: 1;

	h4 {
		font-size: 20px;
		line-height: 1.4;
		margin-bottom: 16px;
	}

	.qr-wrap {
		display: flex;
		gap: 20px;

		.content {
			width: calc(100% - 180px);

			.label {
				margin-left: 20px;
			}
		}

		.qr-code {
			width: 160px;
			height: 160px;
			border: 1px solid $primary_may_75;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 28px;
			overflow: hidden;

			img {
				width: 100%;
				height: auto;
				display: block;
			}
		}
	}

	form {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;

		.input {
			& + .input {
				margin-top: 24px;
			}
		}

		ul {
			padding-left: 0;
			list-style: none;
			margin-bottom: 0;
			line-height: 1.4;
			font-size: 14px;
			line-height: 20px;

			code {
				padding: 8px 16px;
				border-radius: 12px;
				border: 1px solid $primary_may_75;
				background-color: $primary_may_25;
				margin-top: 16px;
				position: relative;
				min-height: 40px;
				display: flex;
				align-items: center;

				.copyToClipboard {
					position: absolute;
					top: 8px;
					right: 16px;
					appearance: none;
					border: none;
					width: 22px;
					height: 22px;
					padding: 0;
					background-color: transparent;
					background-image: url("../../../../../assets/images/icon-copy.svg");
					cursor: pointer;
				}
			}

			input {
				margin-top: 16px;
			}

			li:not(:last-child) {
				margin-bottom: 16px;
			}
		}
	}
}
