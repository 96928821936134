@import '../../../../styles/variables';

.basic-panel {
	padding: 0 0 8px;

	&.disabled {
		opacity: 0.8;
		pointer-events: none;
	}
}
