@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.multiselect-popup {
	border: 1px solid $primary_may_75;
	background-color: $primary_may_10;
	border-radius: 16px;
	border-top-right-radius: 0;
	margin-top: 4px;
	position: absolute;
	top: 100%;
	right: 0;
	display: none;
	min-width: 100%;
	max-width: max-content;
	overflow: hidden;
	max-height: 180px;
	padding: 8px;

	&.opened {
		display: block;
		z-index: 5;
	}
}

//.rw-widget-input
.multiselect-input {
	border: 1px solid $primary_may_75;
	border-radius: 12px;
	background-color: $primary_may_10;
	color: $primary_may_300;
	font-size: 14px;
	line-height: 24px;
	outline: none;
	box-shadow: none;
	transition: all 0.1s linear;
	grid-template: none;
	cursor: pointer;

	&.opened {
		border-bottom-right-radius: 0;
		border-color: $primary_may_200;
	}
}

.multiselect-wrapper {
	transition: all 0.2s linear;

	&.disabled {
		pointer-events: none;
	}

	.label {
		display: block;
		font-size: 10px;
		line-height: 12px;
		font-weight: $medium;
		color: $primary_may_500;
		margin-bottom: 16px;
		text-transform: uppercase;
	}

	.placeholder {
		color: $primary_may_300 !important;
	}

	:global {
		.rw-widget-input {
			transition: all 0.1s linear;
		}

		.rw-state-focus {
			.rw-widget-input {
				box-shadow: none;
				background-color: $primary_may_10;
				border-color: $primary_may_200;
			}
		}

		.rw-state-disabled {
			.rw-widget-input {
				border-color: $primary_may_50;
				background-color: $primary_may_25;
				color: $primary_may_300;
				cursor: not-allowed;
			}

			.rw-placeholder,
			.rw-input::placeholder {
				color: $primary_may_300;
			}
		}

		.rw-picker-caret {
			display: none !important;
		}
	}
}
