
@import '../../../styles/variables';

.selected {
	position: absolute;
	top: -34px;
	right: 0;
	font-size: 14px;
	padding: 6px;
    border-radius: 6px;
    background-color: rgba(#ffffff, 0.8);
    border: 0.5px solid $primary_may_75;
}
