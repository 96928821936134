@import '../../../styles/variables';

.pill {
	display: inline-block;
	font-size: 11px;
	line-height: 16px;
	text-transform: uppercase;
	font-weight: $medium;
	padding: 2px 8px;
	border-radius: 4px;
	margin: 2px;
	border: 1px solid transparent;
	white-space: nowrap;
	transition: all 0.1s linear;

	&.with-close {
		padding-left: 24px;
		position: relative;
	}

	&.with-tooltip {
		cursor: pointer;
	}
}
