@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.drawer {
	padding-right: 8px !important;
}

.edit-company {
	flex-grow: 1;

	form {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;

		.details {
			margin-top: 0;
		}

		.content {
			max-height: calc(100vh - 200px);
		}

		.scrollbar {
			padding-right: 16px;
		}

		.input {
			& + .input {
				margin-top: 24px;
			}
		}

		.input-group {
			margin: 24px 0;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: flex-start;

			.input-item {
				label {
					display: block;
					font-size: 10px;
					line-height: 12px;
					font-weight: $medium;
					color: $primary_may_500;
					margin-bottom: 4px;
				}
			}

			.input {
				margin-top: 0;
				flex: 0 1 calc(50% - 8px);
			}
		}

		.actions {
			padding-right: 16px;

			.actions-group {
				.action + .action {
					margin-left: 16px;
				}
			}
		}
	}
}

.edit-company-skeleton {
	* {
		display: block;
	}

	br {
		display: none;
	}

	.section {
		margin-top: 32px;
	}

	.section-title {
		margin-bottom: 24px;
	}

	.inputs {
		.input {
			& + .input {
				margin-top: 24px;
			}
		}

		.input-group {
			margin: 24px 0;
			display: grid;
			grid-template-columns: calc(50% - 8px) calc(50% - 8px);
			justify-content: space-between;

			&.variables {
				margin-top: 8px;
			}
		}

		.input-with-info {
			margin-top: 24px;
		}
	}
}
