@import '../styles/variables';

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	color: $primary_may_600;
	font-weight: $demiBold;
}

h1 {
	font-size: 28px;
	line-height: 36px;
}

h2 {
	font-size: 24px;
	line-height: 32px;
}

h3 {
	font-size: 20px;
	line-height: 32px;
}

h4 {
	font-size: 14px;
	line-height: 20px;
	font-weight: $semiBold;
}

h5 {
	font-size: 11px;
	line-height: 15px;
	font-weight: $medium;
	color: $primary_may_500;
	text-transform: uppercase;
}

h6 {
	font-size: 14px;
	line-height: 20px;
	font-weight: $medium;
}
