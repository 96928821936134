.back {
	cursor: pointer;
	position: absolute;
	top: 42px;
	left: 24px;

	&:before {
		content: '';
		display: block;
		width: 18px;
		height: 14px;
		background: url('../../../assets/images/icon-arrow-left-dark.svg') no-repeat;
		background-size: contain;
	}
}
