@import '../../../../styles/variables';

.basic-panel {
	padding: 0 0 8px;

	&.disabled {
		opacity: 0.8;
		pointer-events: none;
	}

	.actions {
		margin-top: 0;

		.action {
			& + .action {
				margin-left: 16px;
			}
		}
	}
}

.centered {
	text-align: center;
}
