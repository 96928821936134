@import '../../styles/variables';
@import '../../styles/mixins';

.page-not-found {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
	background-color: $primary_may_25;
	flex-direction: column;
	text-align: center;

	.title {
		font-size: 24px;
		font-weight: $bold;
	}

	.message {
		margin-top: 24px;
		text-align: center;
		font-size: 24px;
	}

	.link {
		@extend %link;
		font-size: 24px;
	}
}
