@import '../../styles/variables';

.reset-password {
	width: 496px;
	min-height: 320px;
	position: relative;

	&.confirmation {
		min-height: 268px;
	}
}
