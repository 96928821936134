@import '../../../styles/variables';

.frameworks-and-controls-block {
	.controls {
		margin-top: 24px;
		padding: 0 16px;

		h5 {
			margin-bottom: 16px;
		}

		.tag {
			&.added-control {
				background-color: $system_green_50;
				border-color: $system_green_75 !important;
			}
		}
	}

	.actions {
		margin-top: 24px;
		text-align: right;
	}

	.empty {
		font-size: 14px;
		line-height: 20px;
		font-weight: $normal;
		color: $primary_blueberry_100;
		margin-top: 16px;
	}
}
