@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.textarea-wrap {
	textarea {
		border: 1px solid $primary_may_75;
		background-color: $primary_may_10;
		border-radius: 12px;
		padding: 8px 12px;
		resize: none;
		font-size: 14px;
		line-height: 20px;
		outline: none;
		width: 100%;
		min-height: 80px;
		max-height: 136px;
		transition: all 0.2s linear;
		color: $primary_may_500;

		&::placeholder {
			color: $primary_may_300;
		}

		&:hover {
			background-color: $primary_may_25;
		}

		&:focus,
		&:active {
			background-color: $primary_may_25;
			border-color: $primary_may_200;
		}

		&:disabled,
		&:read-only {
			background-color: $primary_may_10;
			border-color: $primary_may_50;
			color: $primary_may_300;
			cursor: not-allowed;
		}

		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			margin: 8px 8px 8px 0;
		}
	}

	&.error {
		textarea {
			border-color: $system_red_300;
		}
	}

	&.disabled {
		&.error {
			textarea {
				border-color: $system_red_75;
			}
		}
	}

	&.as-input {
		textarea {
			min-height: 38px;
			height: 38px;
		}
	}
}

.label {
	display: block;
	font-size: 10px;
	line-height: 12px;
	font-weight: $medium;
	color: $primary_may_500;
	margin-bottom: 4px;
}
