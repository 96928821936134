@import '../../../styles/variables';
@import '../../../styles/mixins';

.sidebar {
	background-color: $primary_may_600;
	flex: 0 1 312px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-wrap: nowrap;
	overflow: auto;

	.top {
		.logo {
			background: url('../../../assets/images/logo-gravity-admin-for-dark-bg.svg') no-repeat;
			background-size: contain;
			width: 312px;
			height: 92px;
			text-decoration: none;
			padding: 0;
			display: block;
		}
	}

	.bottom {
		margin: 24px 18px;
	}
}
