@import '../../../styles/mixins';
@import '../../../styles/variables';

.sidebar-layout {
	background-color: $primary_may_50;
	display: flex;
	flex-direction: row;
	width: 100%;
	min-width: 1280px;

	.main {
		padding: 24px;
		max-height: 100vh;
		overflow: hidden;
		flex: 0 1 calc(100% - 312px);

		h2 {
			margin-bottom: 24px;
		}
	}
}
