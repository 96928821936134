@import '../../../../../../styles/mixins';
@import '../../../../../../styles/variables';

.add-admin {
	margin-top: 32px;

	.note {
		margin-bottom: 16px;
	}
	
	.input {
		& + .input {
			margin-top: 16px;
		}
	}

	.actions {
		margin-top: 32px;
	}
}
