@import '../../../styles/variables.scss';

.pagination-wrap {
	margin-top: 24px;
	display: flex;
	justify-content: space-between;

	select {
		background-color: $white;
		border: 1px solid $primary_may_75;
		border-radius: 8px;
		padding: 8px 16px;
		font-size: 16px;
		color: $primary_may_400;
		cursor: pointer;
		transition: all 0.3s ease;
	}

	.pagination {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		list-style: none;
		padding: 0;
		margin: 0;
		list-style: none;

		.next,
		.previous,
		.page-link {
			background-color: $white;
			border: 1px solid $primary_may_75;
			padding: 0;
			cursor: pointer;
			transition: all 0.3s ease;
			height: 40px;
			min-width: 40px;
			border-radius: 8px;

			&:hover,
			&:focus,
			&:active,
			&.active {
				border-color: $primary_may_200;
			}
		}

		.next,
		.previous {
			position: relative;
			border: 1px solid $primary_may_75;
			border-radius: 8px;

			&:before {
				content: '';
				position: absolute;
				top: calc(50% - 5px);
				left: calc(50% - 8px);
				display: inline-block;
				width: 10px;
				height: 10px;
				background-image: url('../../../assets/images/icon-caret-down-dark.svg');
				background-repeat: no-repeat;
				background-size: contain;
			}
		}

		.next {
			margin-left: 14px;

			&:before {
				transform: rotate(270deg);
				left: calc(50% - 2px);
			}
		}

		.previous {
			margin-right: 14px;

			&:before {
				transform: rotate(90deg);
			}
		}

		.page-item {
			display: inline-block;
			margin: 0 2px;
			padding: 0;
			text-align: center;
		}

		.page-link {
			display: inline-block;
			padding: 8px;
			color: $primary_may_400;
			text-decoration: none;

			&.break {
				background-color: transparent;
			}
		}

		.disabled {
			pointer-events: none;

			.previous,
			.next {
				&:before {
					opacity: 0.2;
				}
			}
		}
	}

	&.small {
		.pagination {
			.next,
			.previous,
			.page-link {
				height: 30px;
				min-width: 30px;
			}

			.next {
				margin-left: 10px;
			}

			.previous {
				margin-right: 10px;
			}

			.page-link {
				padding: 4px;
			}
		}
	}
}
