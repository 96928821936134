@import '../../../styles/variables';
@import '../../../styles/mixins';

.user-profile {
	display: block;
	position: relative;
	text-decoration: none;
	outline: none;
	background: linear-gradient(90deg, #5BAF23 0%, rgba(91, 175, 35, 0.536233) 28.5%, rgba(91, 175, 35, 0) 100%);
	border-radius: 24px;
	transition: all 0.1s linear;
	min-height: 96px;

	&:after {
		content: '';
		position: absolute;
		right: 32px;
		top: calc(50% - 7px);
		background-image: url('../../../assets/images/icon-chevron-right-light.svg');
		background-size: contain;
		background-repeat: no-repeat;
		height: 12px;
		width: 6px;
	}

	&.active {
		&:after {
			display: none;
		}
	}

	.user-block {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		position: absolute;
		top: 1px;
		bottom: 1px;
		left: 1px;
		right: 1px;
		padding: 24px 32px 24px 16px;
		border-radius: 24px;
		background-color: $primary_may_600;

		.avatar {
			width: 48px;
			height: 48px;
			border-radius: 50%;
			background: $primary_may_400;
			margin-right: 8px;
			color: $primary_may_50;
			font-weight: 600;
			text-transform: uppercase;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			font-size: 20px;
			line-height: 32px;
			border: 2px solid $primary_may_300;
			transition: all 0.1s linear;
		}

		.info {
			font-size: 14px;
			line-height: 20px;

			.name {
				color: $primary_may_10;
				font-weight: $semiBold;
			}

			.title {
				color: $primary_may_10;
			}
		}
	}

	.logout {
		cursor: pointer;
		position: absolute;
		top: calc(50% - 12px);
		right: 16px;
		width: 24px;
		height: 24px;
		transition: all 0.1s linear;
		border-radius: 4px;

		&:before {
			content: '';
			display: block;
			width: 24px;
			height: 24px;
			background: url('../../../assets/images/icon-logout.svg') no-repeat center center;
			background-size: 12px 12px;
			transition: all 200ms linear;
		}

		&:hover {
			background-color: $primary_may_400;
		}
	}

	&:hover,
	&:focus,
	&:active,
	&.active {
		position: relative;
		border: 1px solid rgba(91, 175, 35, 0.4);
		border-right: none;

		.user-block {
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: linear-gradient(90deg, #5baf23 0%, rgba(91, 175, 35, 0.536233) 28.5%, rgba(91, 175, 35, 0) 100%);
			border-radius: 22px;

			.avatar {
				background: $primary_may_50;
				color: $primary_may_300;
				border-color: $primary_may_100;
			}
		}
	}
}

