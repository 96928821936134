@import '../../../styles/variables';

.main-file {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	padding: 0 40px 0 24px;

	.file-name {
		color: $primary_may_600;
		padding: 8px 40px 8px 0px;
		line-break: anywhere;
	}

	.actions {
		visibility: hidden;
		pointer-events: none;

		&.active {
			visibility: visible;
			pointer-events: all;
		}
	}

	&:hover {
		background-color: $primary_may_25;

		.actions {
			visibility: visible;
			pointer-events: all;
		}
	}
}

.section {
	cursor: pointer;
	padding: 8px 32px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 44px;

	&:hover,
	&.loading {
		background-color: $primary_may_25;

		.status {
			display: none;
		}

		.actions {
			display: flex;
		}
	}

	.status {
		padding: 4px;

		.status-item {
			display: inline-block;
			background-size: contain;
			background-repeat: no-repeat;
			margin-top: 4px;

			&.completed {
				width: 18px;
				height: 13px;
				background-image: url('../../../assets/images/icon-check-success.svg');
			}

			&.failed {
				width: 12px;
				height: 12px;
				background-image: url('../../../assets/images/icon-cross-error.svg');
			}

			&.pending {
				margin-left: 4px;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				animation: dots 1s infinite linear alternate;
			}
			@keyframes dots {
				0% {
					box-shadow:
						10px 0 $primary_may_400,
						-10px 0 $primary_may_75;
					background: $primary_may_400;
				}
				33% {
					box-shadow:
						10px 0 $primary_may_400,
						-10px 0 $primary_may_75;
					background: $primary_may_75;
				}
				66% {
					box-shadow:
						10px 0 $primary_may_75,
						-10px 0 $primary_may_400;
					background: $primary_may_75;
				}
				100% {
					box-shadow:
						10px 0 $primary_may_75,
						-10px 0 $primary_may_400;
					background: $primary_may_400;
				}
			}
		}
	}

	.actions {
		display: none;
		flex-direction: row;
		visibility: hidden;
		width: 20%;
		justify-content: flex-end;
		pointer-events: none;
		align-items: center;

		.action {
			& + .action {
				margin-left: 16px;
			}
		}

		&.active {
			visibility: visible;
			pointer-events: all;
		}
	}

	&:hover {
		.actions {
			visibility: visible;
			pointer-events: all;
		}
	}
}
