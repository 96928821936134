@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.drawer {
	padding-right: 8px !important;
}

.edit-admin {
	flex-grow: 1;

	form {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;

		.content {
			max-height: calc(100vh - 200px);
		}

		.scrollbar {
			padding-right: 16px;
		}

		.input {
			& + .input {
				margin-top: 24px;
			}
		}

		.input-group {
			margin: 24px 0;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: flex-start;

			&:first-child {
				margin-top: 0;
			}

			.input {
				margin-top: 0;
				flex: 0 1 calc(50% - 8px);
			}
		}

		.actions {
			padding-right: 16px;
		}
	}
}

.edit-admin-skeleton {
	* {
		display: block;
	}

	br {
		display: none;
	}

	.inputs {
		.input {
			& + .input {
				margin-top: 24px;
			}
		}

		.input-group {
			margin: 24px 0;
			display: grid;
			grid-template-columns: calc(50% - 8px) calc(50% - 8px);
			justify-content: space-between;

			&.variables {
				margin-top: 8px;
			}
		}
	}
}
