@import '../../../../../../styles/mixins';
@import '../../../../../../styles/variables';

.add-company {
	margin-top: 32px;

	.input {
		margin-top: 16px;
	}

	.actions {
		margin-top: 32px;
	}

	.switcher-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 20px;

		:global {
			.react-switch-bg {
				border: 1px solid $primary_may_75;
			}
		}

		.off {
			:global {
				.react-switch-bg {
					border: 1px solid $primary_may_50;
				}
			}
		}

		.label {
			display: block;
			font-size: 10px;
			line-height: 12px;
			font-weight: $medium;
			color: $primary_may_500;
			margin-bottom: 4px;
		}
	}
}
