@import '../../../../../styles/variables';

.input {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	transition: all 0.3s linear;

	.clear {
		cursor: pointer;
		background-image: url('../../../../../assets/images/icon-cross-thin-accent-light.svg');
		background-size: 12px 12px;
		background-repeat: no-repeat;
		background-position: center;
		border-right: 1px solid $primary_may_75;
		width: 32px;
		height: 100%;

		&:hover {
			background-color: $primary_may_50;
			background-image: url('../../../../../assets/images/icon-cross-thin-accent-dark.svg');
		}

		&.opened {
			border-right-color: $primary_may_200;
		}
	}

	.input-group {
		padding: 6px 32px 6px 12px;
		position: relative;
		width: 100%;

		&:after {
			content: '';
			position: absolute;
			top: calc(50% - 5px);
			right: 14px;
			width: 10px;
			height: 10px;
			background-image: url('../../../../../assets/images/icon-caret-down-light.svg');
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			opacity: 0.7;
		}

		.text {
			line-height: 24px;
			color: $primary_may_300;
		}

		&.opened,
		&.with-selected {
			&:after {
				background-image: url('../../../../../assets/images/icon-caret-down-dark.svg');
				opacity: 1;
			}

			.text {
				color: $primary_may_500;
			}
		}

		&.with-selected {
			width: calc(100% - 32px);
			white-space: nowrap;
		}

		&.opened {
			&:after {
				transform: rotate(180deg);
			}
		}

		.number {
			display: inline-block;
			width: 24px;
			height: 24px;
			border-radius: 50%;
			background-color: $primary_may_200;
			font-size: 12px;
			line-height: 24px;
			font-weight: $medium;
			color: $primary_may_50;
			margin-left: 4px;
			text-align: center;
			vertical-align: bottom;
		}

		&:hover {
			background-color: $primary_may_50;

			.text {
				color: $primary_may_400;
			}

			&:after {
				opacity: 0.8;
			}
		}
	}
}
