@import '../../../styles/variables.scss';

.action-button {
	cursor: pointer;
	width: 24px;
	height: 24px;
	padding: 4px;
	border-radius: 4px;
	position: relative;

	&:before {
		content: '';
		display: block;
		width: 16px;
		height: 16px;
		top: calc(50% - 8px);
		left: calc(50% - 8px);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		position: absolute;
		opacity: 0.8;
	}

	&.delete-user:before {
		background-image: url('../../../assets/images/icon-button-delete-user.svg');
		width: 24px;
		height: 24px;
		top: calc(50% - 14px);
		left: calc(50% - 12px);
	}

	&.download:before {
		background-image: url('../../../assets/images/icon-download.svg');
	}

	&.cancel:before {
		background-image: url('../../../assets/images/icon-close-circle.svg');
	}

	&.link:before {
		background-image: url('../../../assets/images/icon-navigation-accent-light.svg');
	}

	&:hover {
		background-color: $primary_may_25;

		&:before {
			opacity: 1;
		}
	}
}
