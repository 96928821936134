@import '../../../../../../styles/mixins';
@import '../../../../../../styles/variables';

.add-controls {
	margin-top: 32px;

	.actions {
		margin-top: 32px;

		.action {
			& + .action {
				margin-left: 16px;
			}
		}
	}
}
