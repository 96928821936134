@import '../../../styles/variables';

.table {
	width: 100%;
	text-align: left;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	flex-direction: column;

	.thead {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		border-bottom: 1px solid $primary_may_50;
		width: 100%;
		margin-bottom: 8px;
		padding: 8px 0;

		.th {
			font-size: 11px;
			line-height: 24px;
			color: $primary_may_300;
			font-weight: $medium;
			text-transform: uppercase;
			padding: 8px;

			&:first-child {
				padding-left: 16px;
			}

			&:last-child {
				padding-right: 24px;
			}

			&.with-checkbox {
				width: 56px;
			}
		}
	}

	:global {
		.ps__rail-y {
			right: 8px !important;
		}
	}

	.body {
		position: relative;
		max-height: calc(100vh - 300px);
		width: 100%;

		.tr {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
			transition: all 0.2s ease-in-out;
			position: relative;
			cursor: pointer;
			border-bottom: 0.5px solid $primary_may_50;
			padding: 8px 0;

			.row-actions {
				display: none;
				position: absolute;
				top: 16px;
				right: 28px;

				&.display {
					display: flex !important;
				}
			}

			&:hover {
				background: $primary_may_10;

				.row-actions {
					display: flex;
				}
			}

			&.active {
				background: $primary_may_10;
			}

			&.highlighted-success {
				background-color: $system_green_50;
			}

			&.highlighted-error {
				background-color: $system_red_50;
			}

			&.with-checkbox {
				.columns {
					.td:first-child {
						padding-left: 8px;
					}
				}
			}

			.td {
				font-size: 14px;
				line-height: 20px;
				padding: 8px;

				&:first-child {
					padding-left: 16px;
				}

				&:last-child {
					padding-right: 24px;
				}

				&.with-checkbox {
					width: 56px;
				}
			}
		}
	}

	&.small {
		.thead {
			.th {
				padding-top: 2px !important;
				padding-bottom: 2px !important;
				line-height: 16px;
			}
		}

		.body {
			.td {
				padding-top: 2px !important;
				padding-bottom: 2px !important;
			}
		}

		:global {
			.ps__rail-y {
				right: 4px !important;
			}
		}
	}

	&.extra-small {
		.thead {
			margin-bottom: 4px;

			.th {
				padding-top: 0 !important;
				padding-bottom: 0 !important;
				line-height: 16px;

				&:first-child {
					padding-left: 8px;
				}

				&:last-child {
					padding-right: 16px;
				}
			}
		}

		.body {
			.td {
				padding-top: 0 !important;
				padding-bottom: 0 !important;

				&:first-child {
					padding-left: 8px;
				}

				&:last-child {
					padding-right: 16px;
				}
			}
		}

		:global {
			.ps__rail-y {
				right: 4px !important;
			}
		}
	}

	&.no-scroll {
		.thead {
			.th {
				&:last-child {
					padding-right: 16px;
				}
			}
		}

		.body {
			.td {
				&:last-child {
					padding-right: 16px;
				}
			}
		}

		&.extra-small {
			.thead {
				.th {
					&:last-child {
						padding-right: 8px;
					}
				}
			}

			.body {
				.td {
					&:last-child {
						padding-right: 8px;
					}
				}
			}
		}
	}

	.sort-directions {
		display: inline-flex;
		flex-direction: column;
		vertical-align: middle;
		padding: 4px 8px;
		cursor: pointer;
		border-radius: 4px;
		opacity: 0.7;

		&:hover {
			background: $primary_may_50;
			opacity: 1;
		}

		.sort-direction {
			width: 8px;
			height: 8px;
			background-image: url('../../../assets/images/icon-sort-direction.svg');
			background-size: contain;
			background-repeat: no-repeat;


			&.desc {
				transform: rotate(180deg);
			}

			&.active {
				background-image: url('../../../assets/images/icon-sort-direction-active.svg');
			}
		}
	}

	.empty-message {
		justify-content: center;
		text-align: center;
		padding: 0;

		.message {
			position: relative;
			display: inline-block;
			padding: 80px 24px 0;
			color: $primary_may_500;
			max-width: 435px;
			margin: 40px 0 24px;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: calc(50% - 32px);
				display: block;
				width: 64px;
				height: 64px;
				background: url('../../../assets/images/icon-empty-box.svg') no-repeat center;
				background-size: contain;
			}

			&.search {
				&:before {
					background: url('../../../assets/images/icon-empty-search.svg') no-repeat center;
					background-size: contain;
				}
			}

			&.users {
				&:before {
					background: url('../../../assets/images/icon-empty-users.svg') no-repeat center;
					background-size: contain;
				}
			}
		}
	}

	&.skeleton {
		* {
			pointer-events: none;
		}

		.body {
			.tr {
				align-items: center;
			}
		}
	}

	&.scroll-right-0 {
		:global {
			.ps__rail-y {
				right: 0 !important;
			}
		}
	}
}
