@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.log-entry-details {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;

	.title {
		margin-bottom: 16px;
	}

	.details {
		margin-top: 0;
	}

	.actions {
		justify-content: flex-end;
	}
}

.log-entry-details-skeleton {
	* {
		display: block;
	}

	br {
		display: none;
	}

	.section {
		margin-top: 32px;
	}

	.section-title {
		margin-bottom: 24px;
	}

	.item {
		& + .item {
			margin-top: 16px;
		}
	}
}
