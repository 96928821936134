@import '../../../styles/variables.scss';

.tooltip-wrap {
	position: relative;

	.tooltip {
		background-color: $primary_may_50;
		border: 1px solid $primary_may_75;
		padding: 4px 8px;
		border-radius: 8px;
		border-bottom-right-radius: 0;
		color: $primary_blueberry_500;
		font-size: 12px;
		max-width: 375px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		opacity: 1;

		&.dark {
			background-color: $primary_may_400;
			color: $primary_may_10;
			border-color: $primary_may_300;
		}

		&.top-start {
			border-radius: 8px;
			border-bottom-left-radius: 0;
		}

		&.bottom-start {
			border-radius: 8px;
			border-top-left-radius: 0;
		}
	}

	&.with-icon {
		.tooltip {
			&:before {
				content: '';
				display: inline-block;
				vertical-align: top;
				margin-right: 4px;
				background: url('../../../assets/images/icon-exclamation-in-circle-dark.svg')
					no-repeat;
				background-size: contain;
				width: 14px;
				height: 14px;
			}
		}
	}

	&.warning {
		.tooltip {
			border-color: $system_orange_500;
			background-color: $system_orange_50;
		}
	}
}
