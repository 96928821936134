@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.drawer {
	padding-right: 8px !important;
}

.edit-control {
	flex-grow: 1;

	.container {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;

		.details {
			margin-top: 0;
		}

		.name {
			margin-top: 8px;
		}

		.content {
			max-height: calc(100vh - 200px);
		}

		.scrollbar {
			padding-right: 16px;
		}

		.input {
			& + .input {
				margin-top: 24px;
			}
		}

		.actions {
			padding-right: 16px;
			justify-content: flex-end;
		}
	}
}
